import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../../layouts/mdx';
export const _frontmatter = {
  "product": "verify",
  "category": "Integrations",
  "sort": 3,
  "title": "Firebase Authentication with Identity Platform",
  "subtitle": "This tutorial demonstrates how to integrate MitID, Swedish BankID, Norwegian BankID and more eIDs with Firebase using Criipto Verify."
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Firebase Authentication with OpenID Connect`}</h2>
    <p>{`Firebase provides two options for authenticating users: the simpler Firebase Auth and the more comprehensive Firebase Authentication with Identity Platform.`}</p>
    <p>{`The Identity Platform supports OpenID Connect authentication, so you can leverage Criipto as an OpenID provider to authenticate your users in web apps, on Android, and on Apple platforms.`}</p>
    <p>{`In the following tutorial you will first create and register your Criipto application, then configure Firebase Authentication with Identity Platform using Criipto as an OpenID Connect provider.
Finally, you will add the Callback URL from Firebase to your Criipto application.`}</p>
    <h2>{`Register your Criipto Application`}</h2>
    <ol>
      <li parentName="ol">{`Login to your Criipto Verify account - `}<a parentName="li" {...{
          "href": "https://dashboard.criipto.com/applications/add"
        }}>{`https://dashboard.criipto.com/applications/add`}</a></li>
      <li parentName="ol">{`Navigate to Applications.`}</li>
      <li parentName="ol">{`Click the "Add login application" button.`}</li>
      <li parentName="ol">{`Enter application name (in this example we used "Firebase App") and select a domain.`}</li>
      <li parentName="ol">{`Create and save your application, and make a note of your client id.`}</li>
      <li parentName="ol">{`After your application is created, the OpenID Connect tab and enable the OAuth2 Code Flow. Save the changes, and make a note of your application’s OAuth2 client secret.`}</li>
      <li parentName="ol">{`Modify your "User info response strategy" to "plainJson" and save your application.`}</li>
    </ol>
    <h2>{`Firebase application and OIDC provider setup`}</h2>
    <p>{`Once you register your login application at Criipto Verify, you will have access to the following information necessary for leveraging Criipto as an OpenID Connect provider in Firebase Authentication with Identity Platform:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Client ID`}</strong>{` to identify your Criipto tenant to Firebase. In the case below we chose `}<inlineCode parentName="li">{`urn:criipto:samples:no1`}</inlineCode></li>
      <li parentName="ul"><strong parentName="li">{`Client Secret`}</strong>{` is required if you choose the OAuth2 Code Flow - which we recommend.`}</li>
      <li parentName="ul"><strong parentName="li">{`Domain`}</strong>{` that you will use as an Issuer URL in Firebase. We used `}<inlineCode parentName="li">{`samples.criipto.id`}</inlineCode>{` in this example.`}</li>
    </ul>
    <p>{`You can now configure Firebase Authentication with Identity Platform to use Criipto Verify as an OpenID Connect provider.
Your next steps may be slightly different depending on the platform of your choice. The steps below are mainly written for web applications.
Alternatively, you can follow Firebase documentation for `}<a parentName="p" {...{
        "href": "https://firebase.google.com/docs/auth/web/openid-connect"
      }}>{`web applications`}</a>{`, `}<a parentName="p" {...{
        "href": "https://firebase.google.com/docs/auth/ios/openid-connect"
      }}>{`iOS`}</a>{`, or `}<a parentName="p" {...{
        "href": "https://firebase.google.com/docs/auth/android/openid-connect"
      }}>{`Android`}</a>{`.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Create a new Firebase project or use an existing one.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Enable OpenID Connect as a sign-in provider for your Firebase project.
You can do this by going to the Authentication section of your Firebase console and choosing the Sign-in method tab.
Click the "Add provider" button and select "OpenID Connect".
`}<span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "768px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "55.208333333333336%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAABRklEQVQoz31Qi2ocMQy8///CKyWh5Jo2m3155YceluRgb48LNGQYjLAZzXguv16n69Ptx/Pv69Nt3mDdwvuyT0uAWAry97wsG9zewvXl+DPDvB3rHucNpiXEjBn5C5YHL8RCLJBpC+mIBUlOfq1ELsQ42J3PAEjMUqWqmZu5mv3PauZuW9Kff+V5kiPdxUSiqmbWT9XW4e7eBs3sHFrzao7ihS0/nJHzABGVUkSkC+7oW+4YHtrckOgRO4QAAO6eUprnOcYoVaVWdzd31r6uv0bY911VmeXhHCPEGKmD4QAiDEda1l1rFatvYS65uDuWBADMTDxi50LEggOttQywrWuttSCGEFprwrJMi5q6e08k0loj+tQ2EqaUzDzlFWJwM6wWC7qbkK4T9MrV/lXg/kmMXEdJ2tH/NoZuVWtNiDZwXp4zIn8AS/9+SZf+rAMAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "OIDC custom provider",
              "title": "OIDC custom provider",
              "src": "/static/2df8bcb1fe59f05966a86ff2d9d8138b/e5715/OIDC-custom-provider.png",
              "srcSet": ["/static/2df8bcb1fe59f05966a86ff2d9d8138b/8514f/OIDC-custom-provider.png 192w", "/static/2df8bcb1fe59f05966a86ff2d9d8138b/804b2/OIDC-custom-provider.png 384w", "/static/2df8bcb1fe59f05966a86ff2d9d8138b/e5715/OIDC-custom-provider.png 768w", "/static/2df8bcb1fe59f05966a86ff2d9d8138b/4ad3a/OIDC-custom-provider.png 1152w", "/static/2df8bcb1fe59f05966a86ff2d9d8138b/71c1d/OIDC-custom-provider.png 1536w", "/static/2df8bcb1fe59f05966a86ff2d9d8138b/beade/OIDC-custom-provider.png 2266w"],
              "sizes": "(max-width: 768px) 100vw, 768px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`You will now enable and define a new OIDC provider using the `}<a parentName="p" {...{
            "href": "#firebase-application-and-oidc-provider-setup"
          }}>{`information from your Criipto Dashboard and the Client Secret you previously saved`}</a>{`.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Choose the grant type you intend to use. We recommend choosing the OAuth2 Code Flow.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Enter a provider name (in this example we used "Criipto").`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Enter your Criipto Application client id in the "Client ID" field.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Enter `}<inlineCode parentName="p">{`https://{{YOUR_CRIIPTO_DOMAIN}}`}</inlineCode>{` in the "Issuer (URL)" field.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Enter your Criipto Application client secret in the "Client Secret" field and click "Next".
`}<span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "768px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "119.79166666666667%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAYCAIAAAB1KUohAAAACXBIWXMAAAsTAAALEwEAmpwYAAABhElEQVQ4y5WUSW6EMBBFOV2OlRvkJpGyzQ2yySJKsk3Ug6AabHA8YbDLdoTpRmqpm+EtEBJ88+tXUVkIPngfEnEFDqOxIaaXM+zbVnKpBrqum5GNh+8pvn4qa5RzmMWNhBD9xWb2nbuvveYNqRtGKSWEUEqFEGsOyo6N31dGCcYY4wnGmNZ6lThlgLo11tqtJQzikvvf0hgttG4550IIKYcEhRCIuCDu+14qNXXLJ0II3vvlLyNiURSc8xTmKsZzB7G1ljE2Wm3bdl3DwiUw56SUzrnJ+eK0XYkppXmeA8DxeAQAY8x8CWfb9x6vbZW1dix4Md7bYgAghBRFUVUVANR1vcG2cw4R7YUpvHuFXAXW9/38MM3ZzotTXsDpdAI4X8uyrOu6StCEv+yMyUgWwujBeNRSSs65lHIab6XUdHNDHGMkAp/fu7cfNxSz1fbHoX94Io8vfzEG9H76MRY7v3kNXYn3h8Nut/tjdVWV4w4CgKZpVollouu6NmGM0VrPr9FJ/A8w3oKJTQKaKwAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Define new OIDC provide",
              "title": "Define new OIDC provide",
              "src": "/static/efb85980d5f10e41c618bccbe843e8fb/e5715/define-new-OIDC-provider.png",
              "srcSet": ["/static/efb85980d5f10e41c618bccbe843e8fb/8514f/define-new-OIDC-provider.png 192w", "/static/efb85980d5f10e41c618bccbe843e8fb/804b2/define-new-OIDC-provider.png 384w", "/static/efb85980d5f10e41c618bccbe843e8fb/e5715/define-new-OIDC-provider.png 768w", "/static/efb85980d5f10e41c618bccbe843e8fb/dc333/define-new-OIDC-provider.png 938w"],
              "sizes": "(max-width: 768px) 100vw, 768px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`You will now configure the OIDC integration. Copy the Firebase Callback URL and add it to the "Callback URL" field in your Criipto Dashboard.
`}<span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "768px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "21.875%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAIAAAABPYjBAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAkklEQVQI112JCQrDMBAD/f9nhqaOrz0Se31DSdKWUjGIQVLG+WXdHtoAIjExETPzznQKlRxrifW336RWkgrI2obNgbagbXgabxwaj9qC9dhq7q38UWsWiSKHqiVLSpKiSOy9zTnm6GP0Ofsc/ZP27dGb5Lys2vmgjoOYgCnsDLcQBkJPp/hrRya43nBDGAB8SvsLgVDjzTRUI2AAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Callback URL in Criipto Dashboard",
              "title": "Callback URL in Criipto Dashboard",
              "src": "/static/c21da118dea197548485509e9526f77a/e5715/criipto-dashboard-callback-url.png",
              "srcSet": ["/static/c21da118dea197548485509e9526f77a/8514f/criipto-dashboard-callback-url.png 192w", "/static/c21da118dea197548485509e9526f77a/804b2/criipto-dashboard-callback-url.png 384w", "/static/c21da118dea197548485509e9526f77a/e5715/criipto-dashboard-callback-url.png 768w", "/static/c21da118dea197548485509e9526f77a/4ad3a/criipto-dashboard-callback-url.png 1152w", "/static/c21da118dea197548485509e9526f77a/84f4d/criipto-dashboard-callback-url.png 1208w"],
              "sizes": "(max-width: 768px) 100vw, 768px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
    `}</span></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`You are now ready to integrate Firebase into your application by following the steps for the platform of your choice:`}</p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://firebase.google.com/docs/auth/web/openid-connect"
            }}>{`Web applications`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://firebase.google.com/docs/auth/ios/openid-connect"
            }}>{`iOS`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://firebase.google.com/docs/auth/android/openid-connect"
            }}>{`Android`}</a></li>
        </ul>
      </li>
    </ol>
    <p>{`You can handle the entire sign-in flow with the Firebase SDK, or choose to handle it manually. The latter option is useful if you've already implemented one of Criipto's SDKs in your application.`}</p>
    <h3>{`Manual setup for handling the sign-in flow`}</h3>
    <p>{`As mentioned above, you can choose the `}<a parentName="p" {...{
        "href": "https://firebase.google.com/docs/auth/web/openid-connect#manual"
      }}>{`manual setup`}</a>{` and use the ID token directly to authenticate with Firebase.`}</p>
    <p><a parentName="p" {...{
        "href": "/verify/integrations/react/"
      }}>{`In `}<inlineCode parentName="a">{`@criipto/verify-react`}</inlineCode></a>{`, you can use the `}<inlineCode parentName="p">{`id_token`}</inlineCode>{` property of the `}<inlineCode parentName="p">{`result`}</inlineCode>{` object to retrieve the ID token after the user signed in with Criipto Verify.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { useCriiptoVerify } from '@criipto/verify-react';
import { getAuth, signInWithCredential, OAuthProvider } from 'firebase/auth';

const provider = new OAuthProvider('oidc.criipto');

function App() {
  const { claims, result } = useCriiptoVerify();

  // Handle Firebase sign-in flow manually
  useEffect(() => {
    if (!claims) return;
    const credential = provider.credential({
      idToken: result.id_token,
    });

    signInWithCredential(getAuth(), credential)
      .then((result) => {
        // User is signed in.
        // IdP data available in result.additionalUserInfo.profile.

        // Get the OAuth access token and ID Token
        const credential = OAuthProvider.credentialFromResult(result);
        const idToken = credential.idToken;
      })
      .catch((error) => {
        // Handle error.
      });
  }, [claims, result]);

  // ... render your component
}
`}</code></pre>
    <p><a parentName="p" {...{
        "href": "/verify/integrations/javascript/"
      }}>{`Using `}<inlineCode parentName="a">{`@criipto/auth-js`}</inlineCode></a>{` with redirect authorization, you can use the resulting `}<inlineCode parentName="p">{` id_token`}</inlineCode>{` from the `}<inlineCode parentName="p">{`match()`}</inlineCode>{` call to pass to Firebase.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { getAuth, signInWithCredential, OAuthProvider } from 'firebase/auth';

const provider = new OAuthProvider('oidc.criipto');
const match = await criiptoAuth.match();

const credential = provider.credential({
  idToken: match.id_token,
});

signInWithCredential(getAuth(), credential)
  .then((result) => {
    // User is signed in.
    // IdP data available in result.additionalUserInfo.profile.

    // Get the OAuth access token and ID Token
    const credential = OAuthProvider.credentialFromResult(result);
    const idToken = credential.idToken;
  })
  .catch((error) => {
    // Handle error.
  });
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      